import React from "react";
import { Link, withRouter } from "react-router-dom";
import { history } from "../..";
import firebase from "../../firebase";
import { RectShape } from "react-placeholder/lib/placeholders";

import MyLayout from "../Components/MyLayout/index";
import MyDesktop from "../Components/MyComponents/MyDesktop";
import MyMobile from "../Components/MyComponents/MyMobile";
import styled from "styled-components";
import DayTime from "../Components/DayTime";
import HomeProfile from "../Components/HomeProfile";
import HomeStatsCard from "../Components/HomeStatsCard";
import HomeIconCard from "../Components/HomeIconCard";
import HomeRecentVillage from "../Components/HomeRecentVillage";
import ReactPlaceholder from "react-placeholder";
import { Heading4 } from "../Components/FontStyle";

import BgStat1 from "../Assets/img/HomeScreen/img1.svg";
import BgStat2 from "../Assets/img/HomeScreen/img2.svg";
import IconStat1 from "../Assets/img/HomeScreen/icon1.svg";
import IconStat2 from "../Assets/img/HomeScreen/icon2.svg";
import IconAchi2 from "../Assets/img/HomeScreen/icon4.svg";
import IconAchi3 from "../Assets/img/HomeScreen/icon5.svg";
import IconAchi4 from "../Assets/img/HomeScreen/icon6.svg";
import "react-placeholder/lib/reactPlaceholder.css";
import { Tooltip } from "@mui/material";

class HomeScreen extends React.Component {
  state = {
    statData: {},
    states: [],
    villages: [],
    thumbnails: [],
    keys: [],
    user_data: {},
    villageIndex: [],
    villageList: [],
  };
  componentDidMount = () => {
    if (localStorage.getItem("user")) {
      this.fetchStatData({});
    } else {
      history.push("/login");
    }
  };
  fetchStatData = async () => {
    const userId = JSON.parse(localStorage.getItem("user"))?.uid;
    if (userId) {
      const docRef = firebase.firestore().collection("user_data").doc(userId);
      try {
        const doc = await docRef.get();
        if (doc.exists) {
          const data = doc.data();
          this.setState({
            statData: {
              badge: data?.recentBadges?.length ?? 0,
              passport: data?.villages?.length ?? 0,
            },
            states: data?.states ?? [],
            villages: data?.villages?.reverse() ?? [],
            thumbnails: data?.thumbnails?.reverse() ?? [],
            keys: data?.keys?.reverse() ?? [],
          });
        }
      } catch (err) {
        console.log("Error while fetching user data: ", err);
      }
    }
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user?.uid) {
      await firebase
        .firestore()
        .collection("users")
        .doc(user?.uid)
        .get()
        .then((doc) => {
          this.setState({ user_data: doc?.data() });
        })
        .catch((err) => {
          console.log("Error fetching user data", err);
        });
    } else {
      console.log("No user ID found in local storage");
    }
    let recentVillage = [];
    this.state.keys?.slice(0, 3)?.map(async (item) => {
      await firebase
        .firestore()
        .collection("large_village_detail")
        .doc(item)
        .get()
        .then((doc) => {
          let myData = doc.data();
          if (myData != undefined) {
            myData.data.openId = item;
            myData.data.thumbnail_image_id = doc.data()?.thumbnail_image_id;
            myData.data.thumbnail_name = doc.data().thumbnail_name;
            recentVillage.push(myData?.data);
            this.setState({ recentVillages: recentVillage });
          }
        })
        .catch((err) => {
          console.log("errr", err);
        });
    });
  };

  render() {
    const { t } = this.props;
    const { statData } = this.state;
    const number = [1, 2, 3];
    const awesomePlaceholder = (
      <>
        <div
          className="col-sm-12 row px-2 justify-content-center"
          style={{ margin: "0px" }}
        >
          <div className="d-flex col-sm-12 row px-2 my-awesome-placeholder justify-center">
            {number.map((index) => {
              return (
                <div className="th-br-16 col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-3 px-2">
                  <RectShape
                    id={`${index}`}
                    className="th-br-16"
                    color="#DADADA"
                    style={{ height: "150px", width: "135px" }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </>
    );

    const awesomePlaceholderMyMobile = (
      <>
        <div
          className="col-sm-12 row px-2 justify-content-center"
          style={{ margin: "0px" }}
        >
          <div className="d-flex col-sm-12 row px-2 my-awesome-placeholder justify-center">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-3 px-2 d-flex justify-content-center">
              <RectShape
                color="#DADADA"
                style={{ height: "150px", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </>
    );
    return (
      <>
        <MyLayout>
          <div className="col-12">
            <div className="row px-2" style={{ position: "relative" }}>
              <div className="col-md-9">
                <div className="row px-0">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h1 className="th-40 fw-700 th-fair-play text-success">
                      Mera Gaon Meri Dharohar
                    </h1>
                    <h1 className="th-20 fw-700">Dashboard</h1>
                    <p style={{ fontStyle: "italic", textAlign: "center" }}>
                      View your travel history and rewards.
                    </p>
                  </div>
                  <MyMobile>
                    <div className="col-md-8 pb-md-5 pb-3 px-md-2 px-3 mt-4">
                      <DayTime
                        className="h-100"
                        backgroundImage={BgStat1}
                        name={
                          JSON.parse(localStorage.getItem("userdata"))?.name ??
                          ""
                        }
                      />
                    </div>
                  </MyMobile>
                  <MyDesktop>
                    <div className="col-md-8 pb-md-5 pb-3 px-md-2 px-3">
                      <DayTime
                        className="h-100"
                        backgroundImage={BgStat1}
                        name={
                          JSON.parse(localStorage.getItem("userdata"))?.name ??
                          ""
                        }
                      />
                    </div>
                  </MyDesktop>
                  <div className="col-md-4 pb-md-5 pb-3 px-md-2 px-3 twotabs">
                    <div className="row px-2">
                      <div className="col-md-12 col-6 px-2">
                        <HomeStatsCard
                          className="px-md-3 px-2 py-3 mb-3"
                          count={this.state.states.length}
                          captionText="States/UTs Visited"
                          backgroundImage={BgStat1}
                          icon={IconStat1}
                        />
                      </div>
                      <div className="col-md-12 col-6 px-2">
                        <HomeStatsCard
                          className="px-md-3 px-2 py-3"
                          count={this.state.villages.length}
                          captionText="Villages Visited"
                          backgroundImage={BgStat2}
                          icon={IconStat2}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 pb-3 my-achievements">
                    <Heading4 className="mb-2">My Achievements</Heading4>
                    <div className="row web-view justify-content-center">
                      <div className="col-6 ">
                        <Link to="/villageindibadges">
                          <HomeIconCard title="MGMD Smriti" icon={IconAchi2} />
                        </Link>
                      </div>
                      <div className="col-6 pb-3">
                        <Link to="/tickets">
                          <HomeIconCard title="Tickets" icon={IconAchi3} />
                        </Link>
                      </div>
                      <div className="col-6">
                        <Link to="/leaderboard">
                          <HomeIconCard title="Leaderboard" icon={IconAchi4} />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 pb-3 recently-visited-villages recent-moblie-scroll">
                    <Heading4 className="mb-2 px-4">My Recent Travels</Heading4>
                    <MyDesktop>
                      <ReactPlaceholder
                        ready={this.state.recentVillages?.length > 0}
                        customPlaceholder={awesomePlaceholder}
                      >
                        <RecentVillDiv className="p-md-1 p-2">
                          <div
                            className="col-sm-12 row px-2"
                            style={{ margin: "0" }}
                          >
                            {this.state.recentVillages?.map((item, index) => {
                              return (
                                <div
                                  div
                                  key={index}
                                  className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-3 px-2"
                                >
                                  <Link
                                    to={{
                                      pathname: `village-details/${item?.openId}`,
                                      state: { villageId: item?.openId },
                                    }}
                                  >
                                    <HomeRecentVillage
                                      Place={item?.village_name}
                                      State={item?.state_name}
                                      Percent="100"
                                      item={item}
                                    />
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                        </RecentVillDiv>
                      </ReactPlaceholder>
                    </MyDesktop>
                    <MyMobile>
                      <ReactPlaceholder
                        ready={this.state.recentVillages?.length > 0}
                        customPlaceholder={awesomePlaceholderMyMobile}
                      >
                        <RecentVillDiv className="p-md-1 p-2">
                          <div
                            className="col-sm-12 row px-2"
                            style={{ margin: "0" }}
                          >
                            {this.state.recentVillages?.map((item, index) => {
                              return (
                                <div
                                  div
                                  key={index}
                                  className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-3 px-2"
                                >
                                  <Link
                                    to={{
                                      pathname: `village-details/${item?.openId}`,
                                      state: {
                                        villageId: item?.openId,
                                      },
                                    }}
                                  >
                                    <HomeRecentVillage
                                      Place={item?.village_name}
                                      State={item?.state_name}
                                      Percent="100"
                                      item={item}
                                    />
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                        </RecentVillDiv>
                      </ReactPlaceholder>
                    </MyMobile>
                    <Link to="/explore">
                      <div
                        className="text-center pt-2 pb-3"
                        style={{ marginBottom: "150px" }}
                      >
                        <span className="th-btn-green-outline  btn-sm px-4 py-2">
                          EXPLORE MORE
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-md-0 pb-md-0">
                <MyDesktop>
                  <HomeProfile
                    UserName={this.state.user_data?.values?.name}
                    ProfileImage={this.state.user_data?.avatar}
                    indibadges={statData.badge}
                    passports={statData.passport}
                    Description={this.state.user_data?.values?.bio}
                    userCity={this.state.user_data?.values?.city}
                    userState={this.state.user_data?.values?.state}
                  />
                </MyDesktop>
              </div>
            </div>
          </div>
        </MyLayout>
      </>
    );
  }
}
export default withRouter(HomeScreen);

const RecentVillDiv = styled.div`
  border-radius: 0.75rem;
`;
