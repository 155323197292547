import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import MyDesktop from "../../../Components/MyComponents/MyDesktop";
import MyMobile from "../../../Components/MyComponents/MyMobile";
import Default1 from "../../../Assets/img/General/Default1.png";
import Default2 from "../../../Assets/img/General/Default2.png";
import Default3 from "../../../Assets/img/General/Default3.png";
import Default4 from "../../../Assets/img/General/Default4.png";
import Default5 from "../../../Assets/img/General/Default5.png";
import Default6 from "../../../Assets/img/General/Default6.png";
import Default7 from "../../../Assets/img/General/Default7.png";
import Default8 from "../../../Assets/img/General/Default8.png";
import "../index.css";
import Badge from "../../../Assets/img/icons/us-badge.png";

const defaultImages = [
  Default1,
  Default2,
  Default3,
  Default4,
  Default5,
  Default6,
  Default7,
  Default8,
];

const VillageImage = ({ villageData, getData, className, name, isGoodStories }) => {
  const [imgUrl, setImgUrl] = useState("");
  const [showOverlay, setShowOverlay] = useState(false);
  const randomDefaultImage = useRef(
    defaultImages[Math.floor(Math.random() * defaultImages.length)]
  );

  useEffect(() => {
    if (villageData?.thumbnaiId) {
      getData(villageData?.thumbnaiId).then((dd) => setImgUrl(dd));
    } else {
      setShowOverlay(true);
    }
  }, [villageData, getData]);

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <>
      <div
        className={`${className}`}
        style={{
          backgroundPosition: "top",
          width: "100%",
          height: "200px",
          position: "relative",
        }}
      >
        {isGoodStories && (
          <img
            src={ Badge }
            alt="Badge"
            style={ {
              position: "absolute",
              top: "-10px",
              left: "-10px",
              width: "100px",
              height: "100px",
              zIndex: 2,
            } }
          />
        ) }
        <img
          style={ {
            width: "100%",
            height: "200px",
            objectFit: "cover",
            borderRadius: "5px",
          } }
          src={ imgUrl || randomDefaultImage.current }
          onError={ (e) => (e.target.src = defaultImages[0])}
        />
        {showOverlay && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.1)", // Overlay color
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
            }}
          >
            <span
              style={{
                color: "#fff",
                fontSize: "24px",
                textAlign: "center",
                fontFamily: "MyCustomFont",
              }}
            >
              {truncateText(name, 20)}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

const VillageCard = (props) => {
  const getData = async (id, type) => {
    const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/fit-in/400x400/filters:format(webp)/filters:quality(80)/images/${id}`;
    let data = "";
    await axios.get(url).then(function (response) {
      data = response.data.data;
    });
    return data;
  };

  return (
    <React.Fragment>
      <MyDesktop>
        <VillageCardDiv className="gold th-br-12 overflow-hidden">
          <VillageImage
            getData={getData}
            villageData={props}
            name={props?.village_name}
            isGoodStories={props.isGoodStories}
          />

          <div className="th-bg-white village-card-main-container">
            <div className="row align-items-center">
              <div className="row">
                <div className="col-6">
                  <div className="th-12 th-fw-600 text-truncate text-uppercase text-lowercase">
                    {props?.village_name}
                  </div>
                  <div className="th-10 th-grey-2">{props?.state}</div>
                </div>
                {!props.live && (
                  <div className="col-6 d-flex justify-content-end">
                    <div
                      style={{
                        color: "#3CAF5C",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      Coming Soon
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </VillageCardDiv>
      </MyDesktop>
      <MyMobile>
        <div className="th-bg-white th-br-10 p-1 md-2 th-box-shadow">
          <div className="align-items-center">
            <div className="col-12 px-0">
              <VillageImage
                getData={getData}
                villageData={props}
                className="th-bg-lt-pink  th-br-5"
                name={props?.village_name}
              />
            </div>
            <div className="col-12 px-2 pt-1">
              <div className="pl-2">
                <div className="row">
                  <div className="col-6">
                    <div
                      className="th-18 th-fw-600 text-capitalize"
                      style={{ wordBreak: "break-word" }}
                    >
                      {props?.village_name}
                    </div>
                    <div className="th-16 th-grey-2 text-truncate  text-capitalize text-lowercase">
                      {props?.state}
                    </div>
                  </div>
                  {!props.live && (
                    <div className="col-6 d-flex justify-content-end">
                      <div
                        style={{
                          color: "#3CAF5C",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        Coming Soon
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MyMobile>
    </React.Fragment>
  );
};

export default VillageCard;

const VillageCardDiv = styled.div`
  transition: all 0.15s ease;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
`;
