import React, { useEffect, useState } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import firebase, { functions } from "../../../../src/firebase";
import ReactPlaceholder from "react-placeholder";
import styled from "styled-components";
import { RectShape } from "react-placeholder/lib/placeholders";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyLayout from "../../Components/MyLayout/index";
import MyMobile from "../../Components/MyComponents/MyMobile";

import AvatarDefault from "../../Assets/img/General/AvatarDefault.jpg";
import IndibadgesImg from "../../Assets/img/General/indibadges.svg";
import "./index.scss";
import "react-placeholder/lib/reactPlaceholder.css";
import axios from "axios";
import { httpsCallable } from "firebase/compat/functions";
import PageContainer from "../../Components/Container";

function ArtistEdit() {
  const [artistData, setArtistData] = useState([]);
  const [artistDataLoader, setArtistDataLoader] = useState(true);

  //loader state needs to be added here

  const history = useHistory();
  useEffect(() => {
    getArtistData();
  }, []);

  const getArtistData = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    axios
      .get("https://asia-south1-mgmd-356213.cloudfunctions.net/searchArtist/", {
        headers: { "Accept-Encoding": "identity" },
      })
      .then((res) => {
        console.log(res);
        setArtistData(res.data);
        setArtistDataLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
      });

    // firebase
    //   .firestore()
    //   .collection("users")
    //   .where("userType", "==", "artist")
    //   .get()
    //   .then((doc) => {
    //     setArtistDataLoader(false);
    //     let data = [];
    //     doc.docs?.map((item) => {
    //       let obj = item.data();
    //       obj.userId = item.id;
    //       data.push(obj);
    //     });
    //     data = data.sort((a, b) => b.created - a.created);
    //     setArtistData(data);
    //     setTimeout(() => { }, 5000);
    //   });
  };

  const awesomePlaceholder = (
    <>
      <div className="d-flex row my-awesome-placeholder mt-2">
        <div className="col-3">
          <RectShape
            color="#dadada"
            style={{ height: "250px", width: "100%" }}
          />
        </div>
        <div className="col-3">
          <RectShape
            color="#dadada"
            style={{ height: "250px", width: "100%" }}
          />
        </div>
        <div className="col-3">
          <RectShape
            color="#dadada"
            style={{ height: "250px", width: "100%" }}
          />
        </div>
        <div className="col-3">
          <RectShape
            color="#dadada"
            style={{ height: "250px", width: "100%" }}
          />
        </div>
      </div>
      <div className="d-flex row my-awesome-placeholder mt-2">
        <div className="col-3">
          <RectShape
            color="#dadada"
            style={{ height: "250px", width: "100%" }}
          />
        </div>
        <div className="col-3">
          <RectShape
            color="#dadada"
            style={{ height: "250px", width: "100%" }}
          />
        </div>
        <div className="col-3">
          <RectShape
            color="#dadada"
            style={{ height: "250px", width: "100%" }}
          />
        </div>
        <div className="col-3">
          <RectShape
            color="#dadada"
            style={{ height: "250px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );

  const awesomePlaceholderMobile = (
    <>
      <div className="my-awesome-placeholder mt-2">
        <div className="mt-2">
          <RectShape
            color="#dadada"
            style={{ height: "250px", width: "100%" }}
          />
        </div>
        <div className="mt-2">
          <RectShape
            color="#dadada"
            style={{ height: "250px", width: "100%" }}
          />
        </div>
        <div className="mt-2">
          <RectShape
            color="#dadada"
            style={{ height: "250px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );

  const ViewCard = ({ item }) => {
    const [imageData, setImageData] = useState("");
    useEffect(() => {
      const fetchData = async () => {
        try {
          const url = `https://asia-south1-mgmd-356213.cloudfunctions.net/getSignedURLFromCF?url=https://d296j4bbogbdom.cloudfront.net/images/${item?.avatar}`;
          const response = await axios.get(url);
          const data = response?.data?.data;

          setImageData(data);
        } catch (error) {
          console.error("Error fetching image data:", error);
        }
      };

      fetchData();
    }, [item?.avatar]);

    const getStateName = (code) => {
      switch (code) {
        case "AN":
          return "Andaman and Nicobar Islands";
        case "AP":
          return "Andhra Pradesh";
        case "AR":
          return "Arunachal Pradesh";
        case "AS":
          return "Assam";
        case "BR":
          return "Bihar";
        case "CH":
          return "Chandigarh";
        case "CT":
          return "Chhattisgarh";
        case "DN":
          return "Dadra and Nagar Haveli";
        case "DD":
          return "Daman and Diu";
        case "DL":
          return "Delhi";
        case "GA":
          return "Goa";
        case "GJ":
          return "Gujarat";
        case "HR":
          return "Haryana";
        case "HP":
          return "Himachal Pradesh";
        case "JK":
          return "Jammu and Kashmir";
        case "JH":
          return "Jharkhand";
        case "KA":
          return "Karnataka";
        case "KL":
          return "Kerala";
        case "LA":
          return "Ladakh";
        case "LD":
          return "Lakshadweep";
        case "MP":
          return "Madhya Pradesh";
        case "MH":
          return "Maharashtra";
        case "MN":
          return "Manipur";
        case "ML":
          return "Meghalaya";
        case "MZ":
          return "Mizoram";
        case "NL":
          return "Nagaland";
        case "OR":
          return "Odisha";
        case "PY":
          return "Puducherry";
        case "PB":
          return "Punjab";
        case "RJ":
          return "Rajasthan";
        case "SK":
          return "Sikkim";
        case "TN":
          return "Tamil Nadu";
        case "TG":
          return "Telangana";
        case "TR":
          return "Tripura";
        case "UP":
          return "Uttar Pradesh";
        case "UT":
          return "Uttarakhand";
        case "WB":
          return "West Bengal";
        default:
          return code;
      }
    };

    // to disable right click context menu on profile click
    const handleContextMenu = (event) => {
      event.preventDefault();
      const img = document.getElementById("my-image");
      // to disable copy form web
      img.addEventListener("mousedown", (event) => {
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          // Optionally, you can display an error message or take other actions to prevent the user from accessing the image.
        }
      });
    };

    const handleImageError = () => {
      setImageData(AvatarDefault);
    };

    return (
      <MediaCardDiv
        role="button"
        className="gold th-br-12 overflow-hidden position-relative media-card-content"
        onClick={() => history.push(`/view-profile/${item?.userId}`)}
      >
        <div
          className="media-main-image"
          id="my-image"
          style={{
            backgroundImage: `url(${!imageData && AvatarDefault})`,
            backgroundSize: "contain",
            backgroundPosition: "top",
            height: "250px",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
          onError={handleImageError}
          onContextMenu={handleContextMenu}
        >
          <img
            src={imageData ? imageData : AvatarDefault}
            style={{
              width: "auto",
              height: "100%",
            }}
            onError={(e) => (e.target.src = AvatarDefault)}
          />
        </div>
        <div className="th-bg-white  py-2 px-3" style={{ height: "100px" }}>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="th-12 th-fw-600 text-truncate text-capitalize text-lowercase  ">
                {item?.values?.name}
              </div>

              <div className="th-10 th-grey-2 description-text ">
                {item?.values?.city ? item?.values?.city : ""} {""}
                {item?.values?.state ? getStateName(item?.values?.state) : ""}
              </div>
              <div className="th-12 th-fw-500 text-truncate text-capitalize text-lowercase pt-1">
                {item?.values?.arttype
                  ? item?.values?.arttype == "anyother" ||
                    (item?.values?.arttype).includes("Any Other")
                    ? item?.values?.arttypeAny
                    : item?.values?.arttype
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </MediaCardDiv>
    );
  };

  return (
    <PageContainer
      keywords="Village Artists, Cultural Contributors, Folk Artists"
      title="Explore Village Artists | MGMD's Cultural Contributors"
      description="Meet the artists who keep India’s village traditions alive. Explore the profiles of folk artists, cultural contributors, and village craftsmen, preserving India’s rich heritage."
    >
      <MyLayout>
        <MyDesktop>
          <div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 justify-content-end d-flex">
                  <div>
                    <Link
                      className="load-more-button  ml-4 btn px-3 py-2 shadow shadow-sm border"
                      to={
                        localStorage.getItem("user")
                          ? "/artist-explore"
                          : "/login"
                      }
                    >
                      Artist
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <Link
                    className="load-more-button-disabled  ml-4 btn px-3 py-2 shadow shadow-sm border"
                    to={
                      localStorage.getItem("user") ? "/art-gallery" : "/login"
                    }
                  >
                    Artist Gallery
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="App-Artist">
            <div className="container-fluid m-2">
              <ReactPlaceholder
                ready={artistData?.length > 0}
                customPlaceholder={awesomePlaceholder}
              >
                {!artistDataLoader &&
                artistData?.length > 0 &&
                artistData.length == 0 ? (
                  <div className="row d-flex justify-content-center">
                    <div className="bg-white th-br-16 p-3">
                      <div className="col-md-12 col-lg-12 p-md-5 p-3 align-self-center">
                        <div className="text-center">
                          <div className="w-50 mx-auto">
                            <img
                              src={IndibadgesImg}
                              className="edit-media-image"
                              alt="media-image"
                            />
                          </div>
                          <h4 className="pt-3 th-14">No Artists found.</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-3 ">
                    {artistData?.map((hit, index) => (
                      <>
                        {hit?.moderation == true && (
                          <div
                            className="col-lg-3 col-md-6 pb-4 custom artistcard"
                            key={index}
                          >
                            <ViewCard item={hit} />
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                )}
              </ReactPlaceholder>
            </div>
          </div>
        </MyDesktop>
        <MyMobile>
          <div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 justify-content-center d-flex">
                  <div>
                    <Link
                      className="load-more-button  ml-4 btn px-3 py-2 shadow shadow-sm border"
                      to={
                        localStorage.getItem("user")
                          ? "/artist-explore"
                          : "/login"
                      }
                    >
                      Artist
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                  <Link
                    className="load-more-button-disabled  ml-4 btn px-3 py-2 shadow shadow-sm border"
                    to={
                      localStorage.getItem("user") ? "/art-gallery" : "/login"
                    }
                  >
                    Artist Gallery
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="App-Artist">
            <div className="container-fluid">
              <ReactPlaceholder
                ready={artistData?.length > 0}
                customPlaceholder={awesomePlaceholderMobile}
              >
                {!artistDataLoader &&
                artistData?.length > 0 &&
                artistData.length == 0 ? (
                  <div className="row d-flex justify-content-center middle-alignment-artist-explore">
                    <div className="bg-white th-br-16 p-3 h-100">
                      <div className="col-md-12 col-lg-12 p-md-5 p-3 align-self-center">
                        <div className="text-center">
                          <div className="w-100 mx-auto increase-white-bg-artist-explore">
                            <img
                              src={IndibadgesImg}
                              className="edit-media-image"
                              alt="media-image"
                            />
                          </div>
                          <h4 className="pt-3 th-14">No Artists found.</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-3 ">
                    {artistData?.map((hit, index) => (
                      <>
                        {hit?.moderation == true && (
                          <div
                            className="col-lg-3 col-md-6 pb-4 custom artistcard"
                            key={index}
                          >
                            <ViewCard item={hit} />
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                )}
              </ReactPlaceholder>{" "}
            </div>
          </div>
        </MyMobile>
      </MyLayout>
    </PageContainer>
  );
}

const MediaCardDiv = styled.div`
  transition: all 0.15s ease;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
`;

export default withRouter(ArtistEdit);
