import { lazy, Suspense, useEffect } from "react";
import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import indexO from "./App/Screens/VillageDetails/indexO";
import About from "./App/Screens/About";
import VillageDetails from "./App/Screens/VillageDetails/AddVillage";
import userPassport from "./App/Screens/UserPassport";
import Achievements from "./App/Screens/Achievements";
import Index from "./App/Screens/VillageIndiBadges/Index";
import Indexx from "./App/Screens/SuggestEdit";
import LeaderBoard from "./App/Screens/Leaderboard";
import Profile from "./App/Screens/Profile";
import SearchOnMap from "./App/Screens/SearchOnMap";
import Village from "./App/Screens/Village";
import ViewProfile from "./App/Screens/Artist/ViewProfile";
import ArtistEdit from "./App/Screens/Artist/ArtistExplore";
import EditProfile from "./App/Screens/Artist/EditProfile";
import SignUp from "./App/Screens/SignUp/index";
import Login from "./App/Screens/Login/index";
import HomeScreen from "./App/Screens/HomeScreen";
import Onboarding from "./App/Screens/Onboarding/index";
import TermCondition from "./App/Screens/Terms/term";
import { PrivacyPolicy } from "./App/Screens/PrivacyPolicy";
import ArtGallery from "./App/Screens/Artist/ArtGallery";
import VideoGallery from "./App/Screens/VideoGallery";
import CounterComponent from "./App/Screens/CounterComponent";
import firebase from "./firebase";
import { useState } from "react";
import { Disclaimer } from "./App/Screens/Disclaimer";
import Show from "./App/Screens/Show";
import BLive from "./App/Screens/ButtonsLive";
import GoodStories from "./App/Screens/GoodStories";
import Notice from "./App/Screens/Notice";
const routes = [
  {
    path: "/",
    exact: true,
    Component: Onboarding,
  },
  {
    path: "/home",
    exact: true,
    Component: HomeScreen,
  },
  {
    path: "/login",
    exact: true,
    Component: Login,
  },
  {
    path: "/signup",
    exact: true,
    Component: SignUp,
  },
  {
    path: "/artist-profile",
    exact: true,
    Component: EditProfile,
  },
  {
    path: "/artist-explore",
    exact: true,
    Component: ArtistEdit,
  },
  {
    path: "/art-gallery",
    exact: true,
    Component: ArtGallery,
  },
  {
    path: "/view-profile/:artistId",
    exact: true,
    Component: ViewProfile,
  },
  {
    path: "/explore",
    exact: true,
    Component: Village,
  },
  {
    path: "/video-gallery",
    exact: true,
    Component: VideoGallery,
  },
  {
    path: "/good-stories",
    exact: true,
    Component: GoodStories,
  },
  // {
  //   path: "/searchonmap",
  //   exact: true,
  //   Component: SearchOnMap,
  // },
  {
    path: "/profile",
    exact: true,
    Component: Profile,
  },
  {
    path: "/leaderboard",
    exact: true,
    Component: LeaderBoard,
  },
  {
    path: "/village-details/:ind",
    exact: true,
    Component: indexO,
  },
  {
    path: "/notice",
    exact: true,
    Component: Notice,
  },
  {
    path: "/suggest-edit/:key/:name",
    exact: true,
    Component: Indexx,
  },
  {
    path: "/villageindibadges",
    exact: true,
    Component: Index,
  },
  {
    path: "/achievements",
    exact: true,
    Component: Achievements,
  },
  {
    path: "/tickets",
    exact: true,
    Component: userPassport,
  },
  {
    path: "/add-village",
    exact: true,
    Component: VillageDetails,
  },
  {
    path: "/about",
    exact: true,
    Component: About,
  },
  {
    path: "/show",
    exact: true,
    Component: Show,
  },
  {
    path: "/terms",
    exact: true,
    Component: TermCondition,
  },
  {
    path: "/privacy",
    exact: true,
    Component: PrivacyPolicy,
  },
  {
    path: "/disclaimer",
    exact: true,
    Component: Disclaimer,
  },
];
const routes2 = [
  {
    path: "/",
    exact: true,
    Component: CounterComponent,
  },
  {
    path: "/show",
    exact: true,
    Component: Show,
  },
];
const Router = () => {
  const { pathname } = useLocation();
  const [routesMain, setRoutes] = useState([]);

  // useEffect(() => {
  //   console.log = () => {};
  //   console.error = () => {};
  //   console.debug = () => {};
  // }, []);

  useEffect(() => {
    setRoutes(routes);
  }, []);

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      {routesMain.map(({ path, Component, exact }) => (
        <Route
          path={path}
          key={path}
          exact={exact}
          render={() => {
            return (
              // some pages seem to take time to load so leaving suspense in
              // <Suspense fallback={null}>
              <Component />
              // </Suspense>
            );
          }}
        />
      ))}
      {/* {routesMain.length == 1 && <Redirect to="/" />} */}
    </Switch>
  );
};

export default Router;
