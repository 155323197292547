import React, { useEffect } from "react";
import MyLayout from "../../Components/MyLayout";
import "./terms.scss";
import PageContainer from "../../Components/Container";

const TermCondition = () => {
  useEffect(() => {
    const elementDiv = document.querySelector(".th-90-height-scroll");
    elementDiv.scrollIntoView();
  }, []);
  return (
    <PageContainer
      keywords="Terms and Conditions, User Agreement"
      title="Terms and Conditions | MGMD – Guidelines for Using Our Platform"
      description="Review the Terms and Conditions governing the use of MGMD. Understand our policies and your rights as a user to ensure a safe and transparent experience on our platform."
    >
      <MyLayout className="terms_main">
        <section className="container-fluid terms_content">
          <div className="custom-rounded-class-terms-screen">
            <div className="p-2 headingTerms">
              <h4 className="d-flex justify-content-center ">
                Mera Gaon Meri Dharohar
              </h4>
              <div className="p-1">
                <h3 className="d-flex justify-content-center">Terms of Use</h3>
              </div>
            </div>
            <div className="contentTerms p-2">
              <p style={{ justifyContent: "center" }}>
                <br /> <br />
                <b> 1. GENERAL</b>
                <br /> <br /> a) <br /> <br /> This document is an electronic
                record in terms of Information Technology Act, 2000 and rules
                there under as applicable and the amended provisions pertaining
                to electronic records in various statutes as amended by the
                Information Technology Act, 2000. This electronic record is
                generated by a computer system and does not require any physical
                or digital signatures.
                <br /> <br /> b) <br /> <br /> This document is published in
                accordance with the provisions of Rule 3 (1) of the Information
                Technology (Intermediaries guidelines) Rules, 2011 that require
                publishing the rules and regulations, privacy policy and Terms
                of Use for access or usage of mgmd.gov.in.
                <br /> <br /> c) <br /> <br /> The “Mera Gao Meri Dharohar”
                (“the project”) is one of the components of National Mission on
                Cultural Mapping (NMCM), pioneered by Indira Gandhi National
                Centre for the Arts (IGNC
                <br /> <br /> a) <br /> <br />
                and an initiative of the Ministry of Culture, Government of
                India (Ministry) that aims to map the 6.5 Lakh villages of India
                culturally and showcase them on a virtual platform for people to
                visit the unexplored regions of India from anywhere in the
                world.
                <br /> <br /> d) <br /> <br /> The domain name mgmd.gov.in
                ("Website"), including its online services, such as use of
                software on website and information, articles , news, videos,
                blogs and text, graphics, images and information obtained from
                service providers and any other material contained on the
                website is owned and operated by the Ministry of Culture,
                Government of India (“Ministry”), where such expression 1
                CONFIDENTIAL shall, unless repugnant to the context thereof,
                officers, deemed to include its respective representatives,
                administrators and officers.
                <br /> <br /> e) <br /> <br /> For the purpose of these Terms of
                Use (“Terms”), wherever the context so requires,
                <br /> <br /> i) <br /> <br /> The term ‘You’ or “User” shall
                mean any legal person or entity accessing using this Website to
                services provided or registered on this Mobile
                Application/Website, who is competent to enter into binding
                contracts, as per the provisions of the Indian Contract Act,
                1872;
                <br /> <br /> ii) <br /> <br /> The terms ‘We’, ‘Us’ & ‘Our’
                shall mean the website, Mission or Ministry.
                <br /> <br /> iii) <br /> <br /> The terms ‘Party’& ‘Parties’
                shall respectively be used to refer to the User and the Ministry
                individually and collectively, as the context so requires.
                <br /> <br /> f) <br /> <br /> The headings of each section in
                these Terms are only for the purpose of organizing the various
                provisions under these Terms in an orderly manner, and shall not
                be used by either Party to interpret the provisions contained
                herein in any manner. Further, it is specifically agreed to by
                the Parties that the headings shall have no legal or contractual
                value.
                <br /> <br /> g) <br /> <br /> The use of the website by the
                user is solely governed by these Terms as well as the Privacy
                Policy (“Policy”), available on Website and any modifications or
                amendments made thereto by the Ministry from time to time, at
                its sole discretion. Visiting the home page of the Website
                and/or using any of the services provided on the Website shall
                be deemed to signify the User’s unequivocal acceptance of these
                Terms and the aforementioned Policy, and the User expressly
                agrees to be bound by the same. The User expressly 2
                CONFIDENTIAL agrees and acknowledges that the Terms and Policy
                are co-terminus, and that expiry / termination of either one
                will lead to the termination of the other, save as provided in
                Clause 4 hereunder.
                <br /> <br /> h) <br /> <br /> The User unequivocally agrees
                that these Terms and the aforementioned Policy constitute a
                legally binding agreement between the User and the Ministry, and
                that the User shall be subject to the rules, guidelines,
                policies, terms, and conditions applicable to any service that
                is provided by the Website, and that the same shall be deemed to
                be incorporated into these Terms, and shall be treated as part
                and parcel of the same. The User acknowledges and agrees that no
                signature or express act is required to make these Terms and the
                Policy binding on the User, and that the User’s act of visiting
                the any part of the Website constitutes the User’s full and
                final acceptance of these Terms and the aforementioned Policy.
                <br /> <br /> i) <br /> <br /> The Ministry reserves the sole
                and exclusive right to amend or modify these Terms without any
                prior permission or intimation to the User, and the User
                expressly agrees that any such amendments or modifications shall
                come into effect immediately. The User has a duty to
                periodically check the terms and stay updated on its
                requirements. In so far as the User complies with these Terms,
                he/she is granted a personal, non-exclusive, non-transferable,
                revocable, limited privilege to enter and use the Website.{" "}
                <br /> <br />
                <b> 2. ABOUT MERA GAO MERI DHAROHAR (MGMD) PROJECT </b>
                <br /> <br />
                "Mera Gao Meri Dharohar” is an initiative by IGNCA under the
                National Mission on Cultural Mapping (NMCM) launched by the 3
                CONFIDENTIAL Ministry of Culture, Government of India with an
                objective of culturally mapping approximately 6.5 lakh (6,50,000
                approx.) villages across the country. The primary aim of the
                project is to preserve and promote the rich cultural heritage of
                rural India. Each village in India has its own unique
                traditions, customs, art forms, festivals, architecture, and
                local crafts. By mapping and showcasing these aspects, the
                project aims to create awareness about the vibrant cultural
                tapestry that exists in every nook and corner of the country.
                The virtual platform created under the project will serve as an
                interactive portal for visitors to explore the villages
                digitally. It will offer a comprehensive database of information
                about each village, including its historical significance,
                cultural practices, traditional art forms, local cuisine,
                folklore, and natural beauty. The platform will be accessible
                through the internet, enabling people from anywhere in the world
                to virtually visit and learn about these lesser-known regions.
                Through this project, the Ministry of Culture aims to bridge the
                gap between rural and urban India, promoting inclusivity and
                cultural exchange. It will provide a platform for rural
                artisans, craftsmen, and performers to showcase their skills and
                products to a global audience, thereby creating opportunities
                for economic growth and sustainability in rural areas.
                Furthermore, "Mera Gao Meri Dharohar" also seeks to facilitate
                cultural tourism by promoting these villages as potential
                tourist destinations. By showcasing the unique cultural assets
                of each village, the project aims to attract visitors and boost
                tourism in rural areas, thus contributing to the overall
                development of these regions. To accomplish its goals, the
                Ministry of Culture collaborates with various stakeholders,
                including local communities, village panchayats (local
                self-governance bodies), cultural organizations, historians, 4
                CONFIDENTIAL researchers, and technology experts. The project
                involves extensive field surveys, documentation, data
                collection, and digitization processes to create an accurate
                representation of each village's cultural heritage. The
                initiative also encourages active participation from villagers
                themselves, enabling them to contribute information, stories,
                photographs, and videos about their traditions and customs. This
                participatory approach ensures that the project reflects the
                authentic cultural identity and collective memories of each
                village. Overall, "Mera Gao Meri Dharohar" aims to celebrate and
                promote the cultural diversity of India's villages, preserving
                their heritage for future generations and providing a platform
                for cultural exchange and economic opportunities. It endeavors
                to create a global virtual village where people can explore and
                appreciate the hidden gems of rural India, fostering a sense of
                pride and ownership among the local communities while inviting
                the world to experience the unexplored realms of India's
                cultural tapestry. <br /> <br /> <b>3. ELIGIBILITY</b>
                The user represents and warrants that he/she is 18 years of age
                or older, is competent, and eligible to enter into legally
                binding agreements. Furthermore, the user confirms that he/she
                possesses the requisite authority to bind himself/herself to
                these Terms, as determined solely by the provisions of the
                Indian Contract Act, 1872. By accessing and using the "Mera Gao
                Meri Dharohar" website, users affirm that they meet the minimum
                age requirement of 18 years or above to engage in the terms and
                conditions governing the platform. Furthermore, children and
                individuals below the age of 18 are welcome to access and use
                the content of the "Mera Gao Meri Dharohar" website 5
                CONFIDENTIAL under the guidance, supervision and control of
                their parents or legal guardians. The Ministry of Culture
                encourages a safe and enriching experience for all users, and
                parental involvement is essential in ensuring the appropriate
                utilization of the platform by young individual. <br></br>
                <br></br>
                <b>4. TERM </b> <br></br>
                <br></br>These Terms shall continue to form a valid and binding
                contract between the Parties, and shall continue to be in full
                force and effect until:
                <br /> <br /> a) <br /> <br /> The User continues to access and
                use the Mobile Application/Website; or
                <br /> <br /> b) <br /> <br /> The Transaction between the
                Parties, if any, concludes to the satisfaction of both Parties;
                <br /> <br /> c) <br /> <br /> You agree and acknowledge that
                mgmd.gov.in control in any manner any transactions on the
                Website. Accordingly, the contract of sale of services on the
                Website shall be a strictly bipartite contract between you and
                the Website on mgmd.gov.in. <br></br>
                <br></br>
                <b>5. TERMINATION</b> <br></br>
                <br></br>The Ministry reserves the right, in its sole
                discretion, to unilaterally terminate the User’s access to the
                services offered on the Website, or any portion thereof, at any
                time, without notice or cause. The User shall continue to be
                bound by these Terms, and it is expressly agreed to by the
                Parties that the User shall not have the right to terminate
                these Terms till the expiry of the same, as described in Section
                3 herein above. <br></br>
                <b>6. TERMS, CONDITIONS AND DUTIES OF THE USER</b>
                <br></br>
                a. It is the duty of User to provide one’s
                true/accurate/complete information, and all the details relating
                thereof on the Website. If any Use detail found to be false then
                Ministry shall reserve the right on its sole discretion to
                reject the registration and debar the User from using the
                Services available on its Website, without prior intimation
                whatsoever. b. The User agrees to comply with all notices or
                instructions given by Ministry from time to time to enable the
                use of the Services. c. User shall indemnify and hold the
                Ministry, harmless for any loss or damage arising out of User’s
                to comply with any applicable laws or regulations and for breach
                of the following warranties and representations. d. The User
                shall at all times during the pendency of this agreement
                endeavor to protect and promote the interest of the Ministry and
                ensure that there will be no damage to third party due to act/
                omission on the part of the User. e. User is bound not to cut,
                copy, distribute, modify, recreate, reverse engineer,
                distribute, disseminate post, publish or create derivative works
                from, transfer, or sell any information or software obtained
                from the Website. Any such use / limited use of the Website will
                only be allowed with the prior express written permission of the
                Ministry. For the removal of doubt, it is clarified that
                unlimited or wholesale reproduction, copying of the content for
                commercial or non-commercial purposes and unwarranted
                modification of data and information contained on the Website is
                expressly prohibited. 7 CONFIDENTIAL f. The user will be
                responsible for maintaining the confidentiality of the account
                and password for restricting access to your computer to prevent
                unauthorized access to the account. g. The User further
                undertakes not to: i. Engage in any activity that interferes
                with or disrupts access to the Website or the services provided
                therein (or the servers and networks which are connected to the
                Websit
                <br /> <br /> e) <br /> <br />; ii. Publish, post, disseminate,
                any information which is grossly harmful, harassing,
                blasphemous, defamatory, obscene, pornographic, paedophilic,
                libellous, invasive of another's privacy, hateful, or racially,
                ethnically objectionable, disparaging, relating or encouraging
                money laundering or gambling, or otherwise unlawful in any
                manner whatever under any law, rule or regulation currently in
                force; or unlawfully threatening or unlawfully harassing
                including but not limited to "indecent representation of women"
                within the meaning of the Indecent Representation of Women
                (Prohibition) Act, 1986; iii. Post any image/file/data that
                infringes the copyright, patent or trademark of another person
                or legal entity; iv. Upload or distribute files that contain
                viruses, corrupted files, or any other similar software or
                programs that may damage the operation of the Website; v.
                Download any file posted/uploaded by another User of the Website
                that the User is aware, or should reasonably be aware, cannot be
                legally distributed in such a manner; 8 CONFIDENTIAL vi. Probe,
                scan or test the vulnerability of the Website or any network
                connected to the Website, nor breach the security or
                authentication measures on the Website or any network connected
                to the Website. The User may not reverse look-up, trace or seek
                to trace any information relating to any other User of, or
                visitor to, the Website, or any other customer of the Website,
                including any User account maintained on the Website not
                operated/managed by the User, or exploit the Website or
                information made available or offered by or through the Website,
                in any manner; vii. Disrupt or interfere with the security of,
                or otherwise cause harm to, the Website, systems resources,
                accounts, passwords, servers or networks connected to or
                accessible through the Mobile Websites or any affiliated or
                linked Mobile Websites; viii. Use the Website or any material or
                content therein for any purpose that is unlawful or prohibited
                by these Terms, or to solicit the performance of any illegal
                activity or other activity which infringes the rights of this
                Website or any other third party/ies; ix. Violate any code of
                conduct or guideline which may be applicable for or to any
                particular service offered on the Website; x. Violate any
                applicable laws, rules or regulations currently in force within
                or outside India; xi. Violate any portion of these Terms or the
                Policy, including but not limited to any applicable additional
                terms of the Website contained herein or elsewhere, whether made
                by amendment, modification, or otherwise; 9 CONFIDENTIAL xii.
                Threaten the unity, integrity, defence, security or sovereignty
                of India, friendly relations with foreign states, or public
                order, or cause incitement to the commission of any cognizable
                offence, or prevent the investigation of any offence, or insult
                any other nation. xiii. Publish, post, or disseminate
                information that is false, inaccurate or misleading; xiv. Commit
                any act that causes the Ministry to lose (in whole or in part)
                the services of its internet service provider ("ISP") or in any
                manner disrupts the services of any other supplier/service
                provider of the Ministry/Website; xv. The User expressly agrees
                and acknowledges that the Ministry/Website has no obligation to
                monitor the materials posted on the Website, but that it has the
                right to remove or edit any content that in its sole discretion
                violates, or is alleged to violate, any applicable law or either
                the spirit or letter of these Terms. <br /> <br />
                <b>7. DUTIES OF THE MINISTRY</b>
                <br /> <br /> a) <br /> <br /> Cultural Mapping and
                Documentation: The Ministry is responsible for overseeing the
                cultural mapping and documentation process of approximately 6.5
                lakh villages in India. This involves conducting field surveys,
                collaborating with local communities, and collecting
                comprehensive data on each 10 CONFIDENTIAL village's cultural
                heritage, including traditions, art forms, festivals, and
                historical significance.
                <br /> <br /> b) <br /> <br /> User-Driven Data Accuracy: The
                Ministry encourages active user participation in maintaining
                data accuracy. Through surveys, users contribute valuable
                information about the cultural heritage of villages. To ensure
                data integrity, users have the option to make amendments or
                corrections, which will be periodically reviewed and
                incorporated into the dataset. This collaborative approach
                ensures a dynamic and reliable representation of the cultural
                richness of India's villages.
                <br /> <br /> c) <br /> <br /> Virtual Platform Development: The
                Ministry is tasked with developing and maintaining a
                user-friendly virtual platform that showcases the mapped
                villages. This involves creating an interactive website that
                provides visitors with a seamless and immersive experience of
                exploring the cultural richness of rural India. The platform
                should incorporate multimedia elements, such as photographs,
                videos, and to enhance the engagement of users.
                <br /> <br /> d) <br /> <br /> Information Compilation and
                Curation: The Ministry is responsible for compiling and curating
                accurate and up-to-date information about each village. This
                includes gathering historical records, cultural artifacts, local
                stories, and other relevant data that provide an authentic
                representation of the village's heritage. The information should
                be organized and presented in a structured manner on the website
                to ensure easy accessibility and navigation for visitors.
                <br /> <br /> e) <br /> <br /> Collaboration with Stakeholders:
                The Ministry should actively collaborate with various
                stakeholders, including village panchayats, cultural
                organizations, historians, researchers, and technology experts.
                This collaboration should involve seeking input and feedback
                from these stakeholders during the cultural 11 CONFIDENTIAL
                mapping process, as well as fostering partnerships for content
                creation, verification, and knowledge sharing. The Ministry
                should also encourage the active participation of villagers
                themselves, involving them in the documentation process and
                ensuring their voices are represented.
                <br /> <br /> f) <br /> <br /> Respectful Use of Pictures: The
                Ministry ensures that the usage of pictures on the virtual
                platform is done with explicit permission from the concerned
                individuals or communities. This practice is implemented to
                respect the privacy and consent of the people featured in the
                photographs.
                <br /> <br /> g) <br /> <br /> Sensitivity towards Sentiments:
                The Ministry emphasizes its commitment to maintaining
                sensitivity towards the sentiments of individuals and
                communities associated with the "Mera Gao Meri Dharohar"
                project. The platform is designed with the utmost care to avoid
                any content that could potentially hurt religious, cultural, or
                social sensitivities. The Ministry recognizes and respects the
                diverse perspectives and beliefs present within the country,
                striving to create an inclusive and harmonious environment for
                all users thereby upholding the values curated in the
                Constitution of India. Any data inaccuracies can be suggested
                for amendments on the portal .
                <br /> <br /> h) <br /> <br /> Promotion and Outreach: The
                Ministry has a duty to promote the "Mera Gao Meri Dharohar"
                project and the virtual platform to a wider audience. This
                includes implementing an effective marketing and communication
                strategy to raise awareness about the project, its objectives,
                and the unique cultural experiences it offers. The Ministry
                should leverage various channels, including social media,
                traditional media, and collaborations with tourism boards and
                cultural institutions, to promote the website and attract
                visitors from India and around the world. 12 CONFIDENTIAL The
                Ministry of Culture acknowledges that an extensive ef ort has
                been made to collect as much data as possible for the "Mera Gao
                Meri Dharohar" project. The Ministry holds the responsibility
                for overseeing the data collection process. Furthermore, the
                Ministry recognizes the value of community engagement and the
                wealth of knowledge possessed by individuals who reside in these
                villages. As a result, the portal has been designed to
                incorporate a crowdsource data addition feature. This allows
                users, including villagers and other stakeholders, to contribute
                additional information, anecdotes, photographs, and videos to
                enrich the existing data. By embracing the concept of
                crowdsource data addition, the Ministry seeks to enhance the
                comprehensive nature of the platform and create a collective ef
                ort in preserving and promoting the cultural heritage of India's
                villages. The Ministry remains committed to reviewing and
                validating the crowdsource contributions at regular intervals to
                ensure the accuracy and authenticity of the added data. Through
                the collaborative ef orts of the Ministry, communities, and
                users, the "Mera Gaon Meri Dharohar" portal will continue to
                evolve and provide an even more robust representation of the
                cultural heritage of India's villages. <br></br>
                <br></br>
                <b>8. Non-Defamation and Content Responsibility:</b> <br></br>
                <br></br>The Ministry of Culture, along with its associated
                company involved in the "Mera Gao Meri Dharohar'' project,
                cannot be defamed or subjected to any form of defamatory actions
                on any social media platforms or anyway. Both the Ministry and
                the associated company do not hold the responsibility for the
                data and content presented on the platform. Users are encouraged
                to contribute content and data while adhering to accuracy and
                integrity. In the event of any inaccuracies or incompleteness,
                users are granted the option to add or amend the content and
                data within the provided guidelines and framework. 13
                CONFIDENTIAL However, it is strictly prohibited to use the
                platform for malicious or defamatory purposes that harm the
                reputation or dignity of the Ministry, associated company,
                officials, or any other stakeholders. The Ministry and
                associated company reserve the right to take appropriate legal
                action against individuals or entities found guilty of defaming
                or maligning their image. By ensuring responsible contribution
                and respecting the reputation of the Ministry and associated
                company, the platform can continue to provide accurate and
                valuable information about the cultural heritage of India's
                villages, fostering a positive and respectful environment for
                all users. <br></br>
                <br></br>
                <b>9. SUSPENSION OF USER ACCESS AND ACTIVITY</b> <br></br>
                <br></br> Notwithstanding other legal remedies that may be
                available to it, Ministry may in its sole discretion limit the
                User’s access and/ or activity by immediately removing the
                User’s access credentials either temporarily or indefinitely, or
                suspend / terminate the User’s membership, and/or refuse to
                provide User with access to the Website, without being required
                to provide the User with notice or cause:
                <br /> <br /> a) <br /> <br /> If the User is in breach any of
                these Terms or the Policy;
                <br /> <br /> b) <br /> <br /> If the User has provided wrong,
                inaccurate, incomplete or incorrect information;
                <br /> <br /> c) <br /> <br /> If the User’s actions may cause
                any harm, damage or loss to the other Users or to the
                Website/Ministry, at the sole discretion of the Ministry.
                <br /> <br /> d) <br /> <br /> If User’ action ccopying or
                duplicating in any manner any of content or other information
                available from the Website. <br></br>
                <br></br>
                <b>10. INDEMNITY AND LIMITATIONS 14 CONFIDENTIAL</b> <br></br>
                <br></br>The User hereby expressly agrees to defend, indemnify
                and hold harmless the Website and the Ministry, its officials,
                affiliate Users, employees, against any and all claims,
                liabilities, damages, losses, costs and expenses, including
                attorney's fees, caused by or arising out of claims based upon
                the User's actions or inactions, including but not limited to
                any warranties, representations or undertakings, or in relation
                to the non-fulfilment of any of the User’s obligations under
                this Agreement, or arising out of the User's infringement of any
                applicable laws, rules and regulations, including but not
                limited to infringement of intellectual property rights, payment
                of statutory dues and taxes, claims of libel, defamation,
                violation of rights of privacy or publicity, loss of service by
                other subscribers, or the infringement of any other rights of a
                third party. <br></br>
                <br></br>
                <b>11. INTELLECTUAL PROPERTY RIGHTS </b> <br></br> <br></br>
                Unless expressly agreed to in writing, nothing contained herein
                shall give the User a right to use any of the Website’s trade
                names, trademarks, service marks, logos, domain names,
                information, questions, answers, solutions, reports, images,
                videos and other distinctive features, save according to the
                provisions of these Terms. All logos, trademarks, brand names,
                service marks, domain names, including material, designs, and
                graphics created by and developed by the Website and other
                distinctive brand features of the Website are the property of
                the Ministry. Furthermore, with respect to the Website created
                by the Ministry the Ministry shall be the exclusive owner of all
                the designs, graphics and the like, related to the Mobile
                Application/Website. The User may not use any of the
                intellectual property displayed on the 15 CONFIDENTIAL Website
                in any manner that is likely to cause confusion among existing
                or prospective Users of the Website, or that in any manner
                disparages or discredits the Ministry/Website, to be determined
                in the sole discretion of the Ministry. The User is further
                aware that any reproduction or infringement of the intellectual
                property of the aforementioned owners by the User will result in
                legal action being initiated against the User by the respective
                owners of the intellectual property so reproduced / infringed
                upon. It is agreed to by the Parties that the contents of this
                clause shall survive even after the termination or expiry of the
                Terms and/or Policy.
                <br></br>
                <br></br>
                <b>12.DISCLAIMER OF WARRANTIES AND LIABILITIES</b>
                <br /> <br /> a) <br /> <br /> Except as otherwise expressly
                stated on the Website, all services offered on the Website are
                offered on an "as is" basis without any warranty whatsoever,
                either express or implied.
                <br /> <br /> b) <br /> <br /> Information accessed through
                Ministry Website and other touch points is presented in summary
                form and is not exhaustive. Ministry makes no warranties or
                representations as to its accuracy or completeness. This
                information is provided "as is" without warranty of any kind,
                either express or implied, including but not limited to implied
                warranties of merchantability, fitness for particular purpose,
                or non-infringement.
                <br /> <br /> c) <br /> <br /> The Ministry/Website does not
                guarantee that the functions and services contained in the
                Website will be uninterrupted or error-free, or that the Website
                or its server will be free of viruses or 16 CONFIDENTIAL other
                harmful components, and the User hereby expressly accepts any
                and all associated risks involved with the User’s use of the
                Website.
                <br /> <br /> d) <br /> <br /> It is further agreed to by the
                Parties that the contents of this Clause shall survive even
                after the termination or expiry of the Terms and/or Policy.{" "}
                <br></br>
                <br></br>
                <b>13. SUBMISSIONS</b> <br></br>
                <br></br> Any comments, feedback, ideas, suggestions,
                initiation, or any other content contributed by the User to the
                Ministry or this Website will be deemed to include a
                royalty-free, perpetual, irrevocable, nonexclusive right and
                license for the Ministry to adopt, publish, reproduce,
                disseminate, transmit, distribute, copy, use, create derivative
                works, display worldwide, or act on such content, without
                additional approval or consideration, in any media, or
                technology now known or later developed, for the full term of
                any rights that may exist in such content, and the User hereby
                waives any claim to the contrary. The User hereby represents and
                warrants that he/she owns or otherwise controls all of the
                rights to the content contributed to the Website, and that use
                of such content by the Ministry/Website does not infringe upon
                or violate the rights of any third party. In the event of any
                action initiated against the Ministry/Website by any such
                affected third party, the User hereby expressly agrees to
                indemnify and hold harmless the Ministry/Website, for its use of
                any such information provided to it by the User. The Ministry
                reserves its right to defend itself in any such legal disputes
                that may arise, and recover the costs incurred in such
                proceedings from the User. 17 CONFIDENTIAL <br></br>
                <br></br>
                <b>14. DISPUTE RESOLUTION AND JURISDICTION</b> <br /> <br /> It
                is expressly agreed to by the Parties hereto that the formation,
                interpretation and performance of these Terms and any
                disputes/claim/refund/compensation arising here from will be
                resolved through a two-step Alternate Dispute Resolution (“ADR”)
                mechanism. It is further agreed to by the Parties that the
                contents of this Clause shall survive even after the termination
                or expiry of the Terms and/or Policy.
                <br /> <br /> a) <br /> <br /> Mediation: In case of any
                dispute/claim/compensation between the parties, the Parties will
                attempt to resolve the same amicably amongst themselves, to the
                mutual satisfaction of both Parties. In the event that the
                Parties are unable to reach such an amicable solution within
                thirty (30) days of one Party communicating the existence of a
                dispute to the other Party, the dispute will be resolved by
                arbitration, as detailed herein below;
                <br /> <br /> b) <br /> <br /> Arbitration. In the event that
                the Parties are unable to amicably resolve a dispute by
                mediation, said dispute will be referred to arbitration under
                Arbitration and Conciliation Act, 1996 by a sole arbitrator to
                be appointed by the Ministry, and the award passed by such sole
                arbitrator will be valid and binding on both Parties. The
                Parties shall bear their own costs for the proceedings, although
                the sole arbitrator may, in his/her sole discretion, direct
                either Party to bear the entire cost of the proceedings. The
                seat of Arbitration shall be in the New Delhi, India.
                <br /> <br /> c) <br /> <br /> This Agreement shall be governed
                by and construed in accordance with the laws of India. The
                Parties hereby agree to submit to the jurisdiction of the
                competent Courts at New Delhi. 18 CONFIDENTIAL <br></br>
                <br></br> <b>15. NOTICES </b> <br></br>
                <br></br> Any or all kind of communication relating to any
                dispute or grievance experienced by the User may be communicated
                to the Ministry by the User reducing the same to writing, and
                sending the same to the registered office of the Ministry by
                Registered Post Acknowledgement Due / Speed Post Acknowledgement
                Due (RPAD / SPAD) <br /> <br />{" "}
                <b>16. MISCELLANEOUS PROVISIONS</b>
                <br /> <br /> a) <br /> <br /> Entire Agreement: These Terms,
                read with the Policy form the complete and final contract
                between the User and the Ministry with respect to the subject
                matter hereof and supersedes all other communications,
                representations and agreements (whether oral, written or
                otherwis
                <br /> <br /> e) <br /> <br /> relating thereto.
                <br /> <br /> b) <br /> <br /> Waiver: The failure of either
                Party at any time to require performance of any provision of
                these Terms shall in no manner affect such Party's right at a
                later time to enforce the same. No waiver by either Party of any
                breach of these Terms, whether by conduct or otherwise, in any
                one or more instances, shall be deemed to be or construed as a
                further or continuing waiver of any such breach, or a waiver of
                any other breach of these Terms.
                <br /> <br /> c) <br /> <br /> Severability: If any
                provision/clause of these Terms is held to be invalid, illegal
                or unenforceable by any court or authority of competent
                jurisdiction, the validity, legality and enforceability of the
                remaining provisions/clauses of these Terms shall in no way be
                affected or impaired thereby, and each such provision /clause of
                these Terms shall be valid and enforceable to the fullest extent
                19 CONFIDENTIAL permitted by law. In such case, these Terms
                shall be reformed to the minimum extent necessary to correct any
                invalidity, illegality or unenforceability, while preserving to
                the maximum extent the original rights, intentions and
                commercial expectations of the Parties hereto, as expressed
                herein. 20
              </p>
            </div>
          </div>
        </section>
      </MyLayout>
    </PageContainer>
  );
};

export default TermCondition;
